<template>
  <div v-if="getPermissionDateil" class="account-detail-wrap">
    <div style="display: flex; width: 50%; justify-content: space-between">
      <div class="back-to-list-text cus-is-button" @click="$router.go(-1)">
        <el-icon style="margin-right: 18px"><Back /></el-icon>
        返回列表
      </div>
      <div class="back-to-list-text cus-is-button" style="font-weight: bold">
        {{ payload.role_name }}
        <el-icon @click="openDialog('editRoleName')" class="edid-pan-icon"><EditPen /></el-icon>
      </div>
    </div>
    <div class="button-wrap">
      <el-button
        v-if="!$route.path.includes('search')"
        type="info"
        plain
        style="border: none; width: 100%"
        @click="handleDeleteUser(getPermissionDateil)"
      >
        刪除角色
      </el-button>
      <el-button
        v-if="!$route.path.includes('search')"
        @click="handleSave(getPermissionDateil)"
        type="primary"
        style="width: 100%"
      >
        儲存變更
      </el-button>
    </div>
    <div class="account-table">
      <el-tabs v-model="activeName" style="margin-top: 30px">
        <!-- 授權內容 -->
        <el-tab-pane label="授權內容" name="content">
          <div class="check-wrap">
            <div>
              <span>總公司權限</span>
            </div>
            <el-checkbox
              v-model="checkAll1"
              :indeterminate="isIndeterminate1"
              @change="handleHeadOfficeCheckAll"
              :disabled="isAdmin"
            >
              全部
            </el-checkbox>
          </div>
          <div class="check-wrap" v-for="(type, i) in headOffice" :key="i">
            <div>
              <span>{{ type.title }}</span>
            </div>
            <el-checkbox-group v-model="checkedHeadOffice" @change="handleCheckedHeadOfficeChange" :disabled="isAdmin">
              <el-checkbox v-for="item in type.children" :key="item.id" :label="item.id">
                {{ item.title }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
          <el-divider />
          <div class="check-wrap">
            <div>
              <span>分公司權限</span>
            </div>
            <el-checkbox
              v-model="checkAll2"
              :indeterminate="isIndeterminate2"
              @change="handleBranchOfficeCheckAll"
              :disabled="isAdmin"
            >
              全部
            </el-checkbox>
          </div>
          <div class="check-wrap" v-for="(type, i) in branchOffice" :key="i">
            <div>
              <span>{{ type.title }}</span>
            </div>
            <el-checkbox-group
              v-model="checkedBranchOffice"
              @change="handleCheckedBranchOfficeChange"
              :disabled="isAdmin"
            >
              <el-checkbox v-for="item in type.children" :key="item.id" :label="item.id">
                {{ item.title }}
              </el-checkbox>
            </el-checkbox-group>
          </div>
        </el-tab-pane>
        <!-- 使用者管理 -->
        <el-tab-pane label="使用者管理" name="user">
          <el-transfer
            v-model="userArray"
            filterable
            :filter-method="filterMethod"
            :format="{
              noChecked: '${total}',
              hasChecked: '${checked}/${total}',
            }"
            filter-placeholder="請輸入關鍵字"
            :data="userData"
            :titles="[`「${payload.role_name}」以外的使用者`, `「${payload.role_name}」內的使用者`]"
            :button-texts="[`從「${payload.role_name}」移除`, `加入「${payload.role_name}」`]"
          >
            <template #default="{ option }">
              <span>{{ option.label }}</span>
            </template>
          </el-transfer>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
  <!-- 彈窗 -->
  <component :is="dialogTemplate"></component>
</template>
<script>
import { useRoute, useRouter } from 'vue-router';
import { watch, ref, getCurrentInstance, defineAsyncComponent } from 'vue';
import { useGetters, useMutations, useActions } from '@/utils/mapStore';
import { useDialog } from '@/utils/dialog';
export default {
  name: 'permission-detail',
  components: {
    editRoleNameDialog: defineAsyncComponent(() => import('@/components/account/EditRoleName.vue')),
  },
  setup() {
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const route = useRoute();
    const activeName = ref('content');
    //偵測沒有資料
    const { doGetPermissionList, doDeletePermission, doSavePermission } = useActions('permissionManagement', [
      'doGetPermissionList',
      'doDeletePermission',
      'doSavePermission',
    ]);
    const { getPermissionDateil, getRoleName, getPermissionTree, getPermissionUserList } = useGetters(
      'permissionManagement',
      ['getPermissionDateil', 'getRoleName', 'getPermissionTree', 'getPermissionUserList']
    );
    const CLEAR_PERMISSION_DETAIL = useMutations('permissionManagement', ['CLEAR_PERMISSION_DETAIL']);
    if (!getPermissionDateil.value) router.push('/account/permission?page=1');

    watch(route, () => {
      if (!route.fullPath.includes('/detail')) CLEAR_PERMISSION_DETAIL();
    });

    //取得資料
    const headOffice = getPermissionTree.value.headquarters_permission_tree;
    const branchOffice = getPermissionTree.value.permission_tree;

    // 刪除角色
    const handleDeleteUser = (item) => {
      proxy
        .$confirm(`確定要刪除「${item.role_name}」角色？`, '確認刪除', {
          confirmButtonText: '刪除',
          cancelButtonText: '取消',
          type: 'warning',
          confirmButtonClass: 'el-button--danger',
        })
        .then(async () => {
          await doDeletePermission({ role_id: item.role_id });
          await doGetPermissionList({ start: 0, length: 1, type: 'init' });
          router.push('/account/permission?page=1');
          proxy.$message({
            type: 'success',
            message: '刪除成功',
          });
        })
        .catch((e) => e);
    };

    //總公司、分公司全選
    const disabled = ref(false);
    const isAdmin = getPermissionDateil.value.is_admin;

    const checkAll1 = ref(false);
    const checkAll2 = ref(false);
    const isIndeterminate1 = ref(true);
    const isIndeterminate2 = ref(true);
    const checkedHeadOffice = ref([]);
    const checkedBranchOffice = ref([]);
    const doMap = (target) => {
      const idArray = target.map((item) => item.children).map((s) => s.map((x) => x.id));
      const checkedId = [];
      idArray.forEach((i) => {
        checkedId.push(...i.map((x) => x));
      });
      return checkedId;
    };
    // 總、分公司轉成array[string]
    getPermissionDateil.value.headquarters_permission_array.forEach((item) => {
      checkedHeadOffice.value.push(item.id);
    });
    getPermissionDateil.value.permission_array.forEach((item) => {
      checkedBranchOffice.value.push(item.id);
    });

    const handleHeadOfficeCheckAll = (val) => {
      const checkedArr = doMap(headOffice);
      checkedHeadOffice.value = val ? checkedArr : [];
      isIndeterminate1.value = false;
    };
    const handleCheckedHeadOfficeChange = (value) => {
      const checkedCount1 = value.length;
      checkAll1.value = checkedCount1 === headOffice.length;
      isIndeterminate1.value = checkedCount1 > 0 && checkedCount1 < headOffice.length;
    };

    const handleBranchOfficeCheckAll = (val) => {
      const checkedArr = doMap(branchOffice);
      checkedBranchOffice.value = val ? checkedArr : [];
      isIndeterminate2.value = false;
    };
    const handleCheckedBranchOfficeChange = (value) => {
      const checkedCount2 = value.length;
      checkAll2.value = checkedCount2 === branchOffice.length;
      isIndeterminate2.value = checkedCount2 > 0 && checkedCount2 < branchOffice.length;
    };

    // 使用者管理
    const mapUserList = getPermissionUserList.value.user_array;
    const generateData = () => {
      const userData = [];
      const user_id = [];
      const roleId = [];
      mapUserList.map((user) => {
        user_id.push(`${user.user_id}`);
        roleId.push(`${user.role_id}`);
      });
      mapUserList.forEach((item, index) => {
        userData.push({
          label: `${item.user_id} ${item.user_name} ${item.job_title}`,
          key: index,
          user_id: user_id[index],
          roleId: roleId[index],
        });
      });
      return userData;
    };

    // 使用者管理- role_id 不等於 null 為右側框資料
    const userData = ref(generateData());
    const userArray = ref([]);
    const filteredIndices = mapUserList.flatMap((item, index) => (item.role_id !== null ? index : []));
    filteredIndices.forEach((index) => {
      userArray.value.push(index);
    });

    const filterMethod = (query, item) => {
      return item.label.toLowerCase().includes(query.toLowerCase());
    };

    //儲存變更
    const payload = getPermissionDateil.value;
    const handleSave = async () => {
      //合併總公司、分公司id
      let permission_array = [];
      checkedHeadOffice.value.forEach((item) => {
        permission_array.push({
          id: item,
        });
      });
      checkedBranchOffice.value.forEach((item) => {
        permission_array.push({
          id: item,
        });
      });
      //user_array
      const user_array = [];
      mapUserList.forEach((element, index) => {
        if (userArray.value.includes(index)) {
          user_array.push({ user_id: element.user_id });
        }
      });

      const data = {
        role_id: getPermissionDateil.value.role_id,
        role_name: getPermissionDateil.value.role_name,
        user_array: user_array,
        permission_array: permission_array,
        headquarters_permission_array: [],
      };

      try {
        // if (data.permission_array.lenbgth <= 0) {
        await doSavePermission(data);
        await doGetPermissionList({ start: 0, length: 1, type: 'init' });
        router.push('/account/permission?page=1');
        proxy.$message({
          type: 'success',
          message: `儲存成功`,
        });
        // } else {
        //   console.log('至少選一');
        //   proxy.$confirm(`請勾選至少一項授權功能。`, '未選擇授權功能', {
        //     confirmButtonText: '我知道了',
        //     showCancelButton: false,
        //     confirmButtonClass: 'el-button--success',
        //   });
        // }
      } catch (e) {
        console.log(e);
      }
    };

    //彈窗
    const value = payload.role_name;
    const { dialogTemplate, openDialog } = useDialog();
    return {
      userData,
      userArray,
      value,
      dialogTemplate,
      openDialog,
      headOffice,
      branchOffice,
      activeName,
      getPermissionDateil,
      checkAll1,
      checkAll2,
      isIndeterminate1,
      isIndeterminate2,
      checkedHeadOffice,
      checkedBranchOffice,
      handleDeleteUser,
      handleHeadOfficeCheckAll,
      handleBranchOfficeCheckAll,
      handleCheckedHeadOfficeChange,
      handleCheckedBranchOfficeChange,
      handleSave,
      filterMethod,
      payload,
      getRoleName,
      disabled,
      isAdmin,
    };
  },
};
</script>
<style lang="scss" scoped>
$padding-unit: 10px;
$row-height: 55px;
.account-detail-wrap {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: scroll;
  @include white-bg;
  padding: 22px;
}
.check-wrap {
  margin: 8px 0;
  div {
    display: inline-block;
    min-width: 150px;
  }
  .el-checkbox-group {
    display: inline-block;
    min-width: 150px;
    .el-checkbox {
      min-width: 130px;
    }
  }
}
.button-wrap {
  position: absolute;
  top: calc($padding-unit + $row-height / 2);
  right: 22px;
  z-index: 10;
  transform: translateY(-50%);
  width: 232px;
  display: flex;
}
.edid-pan-icon {
  margin-left: 5px;
  color: $color-primary;
}
:deep(.el-transfer) {
  display: flex;
}
:deep(.el-transfer-panel) {
  width: 280px;
}
:deep(.el-transfer__buttons) {
  width: 220px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
}
:deep(.el-transfer__button) {
  width: 160px;
  margin: 20px 0;
}
:deep(.el-input__wrapper) {
  width: 250px;
}
</style>
